import { createStore } from "vuex";
import getters from "./getters";
import information from "./modules/infomation";
const store = createStore({
  modules: {
    information,
  },
  getters,
});

export default store;
