import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vconsole from "vconsole";
let vConsole;
if (
  process.env.VUE_APP_ENV === "test" ||
  process.env.VUE_APP_ENV === "development"
) {
  vConsole = new Vconsole();
}
export default vConsole;

//css单位自适应插件
import "lib-flexible";
import "element-plus/theme-chalk/el-message.css";
import VueResizeObserver from "vue-resize-observer";

const app = createApp(App);
// app.config.globalProperties.$Dialog = Dialog;
app.use(store).use(router);
app.use(VueResizeObserver);

app.mount("#app");
