import "core-js/modules/es.array.push.js";
import { useRouter, useRoute } from "vue-router";
export default {
  name: "App",
  components: {},
  setup: function setup(props, context) {
    var router = useRouter();
    var route = useRoute();
    var toTicketRefundView = function toTicketRefundView() {
      router.push({
        name: "memberLogin",
        params: {
          pageTitle: "登录/注册"
        }
      });
    };
    return {
      toTicketRefundView: toTicketRefundView
    };
  }
};