const information = {
  state: {
    myMemberId: "",
    myInfo: {},
  },
  mutations: {
    setMyMemberId(state, memberId) {
      if (memberId) {
        state.myMemberId = memberId;
      } else {
        state.myMemberId = "";
      }
    },
    setMyInfo(state, info) {
      if (info) {
        state.myInfo = info;
      } else {
        state.myInfo = {};
      }
    },
  },
};
export default information;
