import { createRouter, createWebHistory } from "vue-router";
const routes = [
  //登录页面
  {
    path: "/login",
    name: "memberLogin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MemberLogin.vue"),
  },
  //个人中心主页
  {
    path: "/",
    name: "memberHome",
    component: () => import("../views/MemberHome.vue"),
  },
  //忘记密码页
  {
    path: "/forget",
    name: "forget",
    component: () => import("../views/Forget.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory("/"),
  routes,
});

export default router;
